<template>
  <div class="overflow-hidden">
    <div class="row mb-5 d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <v-img
          src="@/assets/images/banners/superior_password.svg"
          alt="login"
        />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 col-md-5 col-lg-3 mx-auto">
        <div class="mx-auto password-reset">
          <div>
            <h2>Restablecer Contraseña</h2>
            <p>
              Ingresa tu correo electrónico y te enviaremos un mail con las
              instrucciones para crear una nueva contraseña
            </p>
          </div>
          <div>
            <form novalidate autocomplete="off" @submit.prevent="passwordReset">
              <validation-observer ref="form">
                <validation-provider
                  v-slot="{ errors }"
                  name="Correo electrónico"
                  rules="required|email"
                  vid="email"
                >
                  <v-text-field
                    class="rounded-clg"
                    v-model="model.email"
                    type="email"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Correo electrónico"
                    required
                  ></v-text-field>
                </validation-provider>
                <v-btn
                  color="secondary"
                  type="submit"
                  class="rounded-lg text-capitalize black--text mb-9"
                  block
                >
                  Restablecer Contraseña
                </v-btn>
              </validation-observer>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <v-img
          src="@/assets/images/banners/inferior_password.svg"
          alt="login"
        />
      </div>
    </div>
  </div>
</template>

<script>
import crudServiceMixin from "@/mixins/crudServiceMixin";
import notificationMixin from "@/mixins/notificationMixin";
import loaderMixin from "@/mixins/loaderMixin";
import { PASSWORD_RESET_URL } from "@/constants/ServicesConstants";

export default {
  name: "PasswordReset",
  data: () => ({
    model: {
      email: "",
    },
  }),
  mixins: [crudServiceMixin, notificationMixin, loaderMixin],
  methods: {
    passwordReset() {
      this.model.fairSlug = this.$store.getters["fairs/slug"];
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.showLoader();
          this.post(PASSWORD_RESET_URL, this.model)
            .then(() => {
              this.showSuccess(
                "Se le ha enviado un enlace de recuperación a su correo electrónico.",
                5000
              );
              this.hideLoader();
            })
            .catch((e) => {
              this.hideLoader();
              this.$refs.form.errors.email.push(
                e.response.data.error || "Ha ocurrido un error."
              );
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.password-reset {
  width: 80%;
}
</style>
